import * as React from "react";
import styled from "@emotion/styled";

interface Props {}

export const JoinLinnComponent: React.FunctionComponent<Props> = ({}) => {

    return (

             <>
                <Title>
                    MY PROMISES TO YOU
                </Title>
                <br></br>
                <BulletPointBox>
                    <IconImage 
                        src="https://fitplan-marketing.s3-us-west-2.amazonaws.com/linnlowes/community200.png" 
                        alt="Fitplan icon Heart"
                    />
                    <ParagraphContent>
                        You will feel stronger and more confident 
                        than ever before.
                    </ParagraphContent>        
                </BulletPointBox>
                <GreyDivider/>
                
                <BulletPointBox>
                    <IconImage 
                        src="https://fitplan-marketing.s3-us-west-2.amazonaws.com/linnlowes/results200.png" 
                        alt="Fitplan icon Phone"
                    />
                    <ParagraphContent>
                        You will see the results you’ve always 
                        dreamed of.
                    </ParagraphContent>        
                </BulletPointBox>
                <GreyDivider/>

                <BulletPointBox>
                    <IconImage 
                        src="https://fitplan-marketing.s3-us-west-2.amazonaws.com/linnlowes/community200.png" 
                        alt="Fitplan icon 24/7"
                    />
                    <ParagraphContent>
                        You will never feel alone on your fitness 
                        journey ever again.
                    </ParagraphContent>        
                </BulletPointBox>
                <GreyDivider/>
                
                <BulletPointBox>
                    <IconImageVariety 
                        src="https://fitplan-marketing.s3-us-west-2.amazonaws.com/linnlowes/fun200.png" 
                        alt="Fitplan icon Infinity"
                    />
                    <ParagraphContent>
                        You will have more fun with your workouts 
                        than ever before.
                    </ParagraphContent>
                </BulletPointBox>
                <GreyDivider/>

            </>
    );
};


const BulletPointBox = styled.div`
    width: 200px;
    margin: 10px calc(50vw - 100px);

    display: block;
    @media (min-width: 900px){
        margin: auto 10px;
    
        display: inline-block;
        vertical-align: top;
    }
    @media (min-width: 1200px){
    }
`;
const Title = styled.h1`
    font-size: 48px;
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;

    margin: 0 auto;
    padding: 0;
`;

const IconImage = styled.img`
    height: 100px;
    vertical-align: top;
`;
const IconImageVariety = styled(IconImage)`
    width: 100px;
`;
const ParagraphContent = styled.p`
    width: 200px;
    margin: 10px auto;
`;
//===============================================>       Divider
const GreyDivider = styled.div`
    background-image: linear-gradient(#1d222b, #777777, #1d222b);

    width: 140px;
    height: 2px;
    margin: 20px calc(50vw - 70px);

    display: block;

    @media (min-width: 900px){
        
        display: none;
    }
    @media (min-width: 1200px){
    }
`;