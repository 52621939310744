import * as React from "react";

export interface Props {
    src: string;
}

export const GuestpassVideo: React.SFC<Props> = ({ src }) => {
    return (
        <video
            playsInline
            autoPlay
            muted
            loop
            id="bgvid"
            ref={video => {
                if (!video) return;
                video.muted = true;
                video.play();
            }}
        >
            <source
                src={src}
                type="video/mp4"
            />
        </video>
    )
};
