import * as React from "react";
import styled from "@emotion/styled";

import { LinnSubscribe }     from "../../../components/linn-landing/LinnSubscribe";
import { GuestpassVideo }    from "../../../components/guestpass/GuestpassVideo";
import { JoinLinnComponent } from "../../../components/linn-landing/JoinLinnComponent";

import "../../../components/linn-landing/linn-button.scss";
import NotFoundPage from "../../404";

interface Props {}

const tabletBreakpoint = "900px";
const mobileBreakpoint = "500px";

const LinnLowesLandingPage: React.FunctionComponent<Props> = () => {
    return <NotFoundPage/>
    return (
        <main className="linn">
            <LocalWrapper>
                <VideoWrapper>
                    <HeroWideBox>
                            <GuestpassVideo
                                src="https://player.vimeo.com/external/395830932.hd.mp4?s=d355e4cba477d5307c66b116a78ba687d56f5b7e&profile_id=174"
                            />
                        </HeroWideBox>
                        <HeroMobileBox>
                            <GuestpassVideo
                                src="https://player.vimeo.com/external/395830912.hd.mp4?s=e458a41847275675b00ec0934119ebc30d744a75&profile_id=174"
                            />
                    </HeroMobileBox>
                </VideoWrapper>
                <GradientContainer>
                    <QuoteBox>
                        Do something{" "}
                        <b>today</b>{" "}
                        that your{" "}<Break/>
                        future self will thank you for.
                    </QuoteBox>
                </GradientContainer>
                <BottomContainer>         
                    <BulletPointContainer>    
                        <JoinLinnComponent />
                    </BulletPointContainer>
                    <PurchaseContainer>
                        <Header>
                            Take $19.99 off your
                            {" "}<Break />
                            <u>
                                annual
                            </u>{" "}
                            membership
                        </Header>                      
                        <Promotion>
                            <br/>
                            <LeftPromo>PROMO CODE:</LeftPromo>
                            {" "}<Break />
                            <RightPromo>LION50FP</RightPromo>
                            <br/><br/>
                            <SmallRules>(Offer valid for new clients only.)</SmallRules> 
                        </Promotion>
                        <section className="linnBtn">
                            <LinnSubscribe newCustomersOnly={(
                                <div>
                                    <h1>ERROR</h1>
                                    <h2>This deal is for new members only.</h2>
                                </div>
                            )} />
                        </section>
                    </PurchaseContainer>
                    <BottomQuote>
                        A little progress each day
                        {" "}<Break/>
                        adds up to big results.
                    </BottomQuote>
                </BottomContainer>
            </LocalWrapper>
        </main>
    );
};

const BottomContainer = styled.div`
    height: 100vh;
    background-color: #fff;
    box-shadow: inset 0 20px 19px -20px rgba(0,0,0,0.3);

    position: relative;
`;

const SmallRules = styled.div`
    font-size: 12px;
    font-style: italic;
`;
const LeftPromo = styled.span`
    font-family:'Oswald';
    font-size: 28px;
    color: #505050;

    user-select: none; 
    -webkit-user-select: none; 
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
`;
const RightPromo = styled.span`
    font-family:'Oswald';
    font-size: 28px;

    color: #B7C6B1;
    background-color: #fff;

    padding: 2px 15px;   
`;
//===============================================>       Local
const LocalWrapper = styled.div`
    font-family: Barlow;
    color: #505050;
    background-color: #fff;

    position: relative;
`;
const Break = styled.br`
    @media (min-width: ${tabletBreakpoint}){
        display: none
    }
`;
//===============================================>       Background Hero Videos
const HeroMobileBox = styled.div`
    text-align: center;
    overflow: hidden;
    visibility: visible;

    video {
        z-index: 1 !important;
        position: absolute;
        margin: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @media (min-width: ${mobileBreakpoint}) {
        display: none;
        visibility: hidden;
    }
    @media (max-aspect-ratio: 9/16) {
        video {
            height:100%;
        }
      }
    @media (min-aspect-ratio: 9/16) {
        video {
            width: 100vw;
            height: auto;
        }
      }
`;

const HeroWideBox = styled.div`
    display: none;
    visibility: hidden;
    
    video {
        z-index: 1 !important;
        position: absolute;
        margin: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow-anchor: auto;
    }

    @media (min-width: ${mobileBreakpoint}){
        display: block;
        visibility: visible;
    }
    @media (max-aspect-ratio: 16/9) {
        video {
            height: 100vh;
            width: auto;
        }
      }
    @media (min-aspect-ratio: 16/9) {
        video {
            width: 100vw;
            height: 56.25vw;
        }
      }
`;

const VideoWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    
    position: fixed;
`;

//===============================================>       GradientContainer
const GradientContainer = styled.div`
    text-align: center;

    color: #fff;
    background-image:linear-gradient(transparent, transparent, #FEDEDF);
    background-color: rgba(0,0,0,.1);

    width: 100vw;
    height: 100vh;
    overflow: scroll;

    position: sticky;
    z-index: 10;
`;

const QuoteBox = styled.div`
    font-family: Josefin Sans;
    font-weight: 100;
    font-size: 4vw;
    font-style:italic;
    
    color: #505050;
    text-align: center;

    width: 100vw;
    margin: 20px auto;
    
    position: absolute;
    bottom: 0;
    z-index: 10;

    @media (min-width: ${mobileBreakpoint}) {
        font-size: 3.25vw;
    }
`;


//===============================================>       Purchase Content
const PurchaseContainer = styled.div`
    text-align: center;
    width:100vw;
    background: #FEDEDF;

    position:relative;
`;
const Header = styled.h2`
    font-weight: 800;
    text-align: center;
    font-size: 36px;
    text-transform: uppercase;

    color: #505050!important;
    margin: 5vw auto 20px;

    vertical-align: top;
    display: inline-block;
    
    @media (min-width: ${tabletBreakpoint}){
        margin: 0 auto;
        font-size: 30px;
        display: block;
    }
    z-index: 10;
`;
//===============================================>       Bullet Points
const BulletPointContainer = styled.div`
    text-align: center;
    background-color: #fff;
    padding: 40px 0;
    
    position:relative;
    z-index: 100;
`;
const BottomQuote = styled.section`
    font-family: 'Dancing Script';
    font-size: 20px;
    font-style: italic;
    text-align: center;

    height: 150px;
    width: 100vw;
    padding-top: 80px;
    background-color: #fff;

    position: relative;
`;
const Promotion = styled.div`
    width: 100vw;
`;

export default LinnLowesLandingPage;